import { FC } from 'react';
import CustomDrawer from '@src/components/CustomDrawer.tsx';
import { Dispatch, SetStateAction } from 'react';
import { TRegisterCompanyRequest } from '@src/pages/auth/RegisterCompany.tsx';
import Input from '@src/components/Input.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { isNotEmpty } from '@src/util/functions.ts';
import { COLORS } from '@src/util/constants.ts';

interface RegisterCompanyInfoDrawerProps {
  drawerOpen: boolean;
  setDrawerOpen: Dispatch<SetStateAction<boolean>>;
  companyInfo: TRegisterCompanyRequest | null;
  registerCompany: (companyName: string) => void;
  isLoading: boolean;
}

const CompanyInputForm = z.object({
  companyName: z.string().min(1, { message: '회사명을 입력해 주세요.' }),
});

type TCompanyInputForm = z.infer<typeof CompanyInputForm>;

const RegisterCompanyInfoDrawer: FC<RegisterCompanyInfoDrawerProps> = ({
                                                                         drawerOpen,
                                                                         setDrawerOpen,
                                                                         companyInfo,
                                                                         registerCompany,
                                                                         isLoading,
                                                                       }) => {
  const {
    register,
    reset,
    getValues,
    formState: { errors },
    setError,
  } = useForm<TCompanyInputForm>({
    resolver: zodResolver(CompanyInputForm),
    mode: 'onSubmit',
  });

  return (
    <CustomDrawer
      open={drawerOpen}
      setOpen={setDrawerOpen}
      cancelButton={'취소'}
      onCancel={() => {
        reset();
        setDrawerOpen(false);
      }}
      okButton={'추가'}
      isLoading={isLoading}
      onOk={() => {
        if (isNotEmpty(getValues('companyName'))) {
          registerCompany(getValues('companyName'));
          reset();
        } else {
          setError('companyName', {
            type: 'custom',
            message: '상호를 입력해 주세요.',
          });
        }
      }}
    >
      <form className={'register-company-info-container'}>
        <div
          className={'register-company-info-row'}
          style={{ padding: '0 0 8px 0' }}
        >
          <p className={'text-base'} style={{ color: COLORS['primary'] }}>
            상호를 입력해 주세요.
          </p>
        </div>

        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            상호
          </p>
          <div className={'flex-column-flex-start-center'}>
            <Input register={register('companyName')} />
            {errors.companyName && (
              <p className={'error-message'}>{errors.companyName.message}</p>
            )}
          </div>
        </div>
        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            사업자등록번호
          </p>
          <p className={'text-regular'}>{companyInfo?.resident_number}</p>
        </div>
        <div className={'register-company-info-row'}>
          <p className={'text-regular-bold'} style={{ width: '120px' }}>
            과세 유형
          </p>
          <p className={'text-regular'}>
            {companyInfo?.tax_type === 'TAXABLE' ? '과세' : '면세'}
          </p>
        </div>
      </form>
    </CustomDrawer>
  );
};

export default RegisterCompanyInfoDrawer;
